<template>
	<div v-loading='pageloading'>
		<el-form :model="ruForm" :rules="rules" ref="ruForm">
			<el-card shadow='never'>
				<div class="titleFont">活码信息</div>
				<div style="margin-top:30px">
					<el-form-item label="活码名称：" label-width="100px" prop="GroupLiveCodeName" ref="GroupLiveCodeName">
						<el-input v-model="ruForm.GroupLiveCodeName" style="width:300px" placeholder="请输入活码名称,最多15个字"
							maxlength="15"></el-input>
					</el-form-item>
					<el-form-item label="加群提示：" label-width="100px">
						<el-input type="textarea" v-model="ruForm.JoinGroupTip" style="width:300px"
							:autosize="{ minRows: 3, maxRows: 6 }" placeholder="群内购物专属折扣！更有“粉丝”专属福利等你参与！快来加群吧〜"
							maxlength="30" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="适用门店：" label-width="100px" prop="ShopId" ref="ShopId">
						<el-select style="width:300px" filterable v-model="ruForm.ShopId" placeholder="请选择门店">
							<el-option v-for="(v,i) in storesList" :key="v.ShopId" :value="v.ShopId"
								:label="v.ShopName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="JoinService-Class" label="加群客服：" label-width="100px" prop="CustomerId"
						ref="CustomerId">
						<el-select style="width:300px" v-model="ruForm.CustomerId" filterable placeholder="请选择员工">
							<el-option v-for="(v,i) in JoinServiceList" :key="i" :value="v.Id" :label="v.EmployeeName">
							</el-option>
						</el-select>
						<br />
						<span style="font-size:13px;color:rgb(180,180,180)">客户加群过程中若遇到问题可联系客服</span>
					</el-form-item>
				</div>
			</el-card>
			<el-card shadow="never" style="margin:10px 0px 100px 0px">
				<div class="titleFont">上传活码</div>
				<div style="margin:30px 0px 20px 0px">
					<div class="blueBox" style="text-align:center">
						<p style="font-size:14px;">请上传在企业微信后台创建的活码。上传的 「企业微信群活码」和 「对应群聊」中添加的客户群需要和企业微信后台保持一致
							<span type="text" style="padding:0px;margin:0px 15px;color:#409eff;cursor:pointer">
								<a href="https://work.weixin.qq.com/wework_admin/loginpage_wx?from=myhome"
									target="_blank">去创建</a>
							</span>
							<span type="text" style="color:#409eff;cursor:pointer">
								<a href="https://jusnn6k8al.feishu.cn/docs/doccn4OVPsZOLFmgWUc6QB5AnN4"
									target="_blank">查看如何创建</a>
							</span>
						</p>
					</div>
				</div>
				<el-form-item v-for="(v,i) in ruForm.GroupLiveCodeDetail" :key="v.id" :label="'群码'+(i+1)+'：'"
					label-width="100px">
					<el-button type="text" style="margin-left:445px;color:rgb(245,108,108)"
						v-if="ruForm.GroupLiveCodeDetail.length>1" @click="detele(i)">删除群码</el-button>
					<br />
					<div style="width:500px;border:1px solid rgb(200,200,200);background:rgb(245,245,245);padding:30px">
						<el-form-item label="企微活码：" label-width="100px"
							:prop="'GroupLiveCodeDetail.'+i+'.QyWeixinGroupLiveCodeImgUrl'"
							:rules="rules.QyWeixinGroupLiveCodeImgUrl"
							:ref="'GroupLiveCodeDetail.'+i+'.QyWeixinGroupLiveCodeImgUrl'">
							<div style="width:100%;display:flex;flex-direction:row">
								<img v-if="v.QyWeixinGroupLiveCodeImgUrl" :src="imgUrl+v.QyWeixinGroupLiveCodeImgUrl"
									style="width:146px;height:146px;margin-left:-10px" alt="" />
								<el-upload ref="uploader" :action="imgApi" :file-list="[]"
									style="height:25px;width:60px;"
									:class="{elUploadClass:v.QyWeixinGroupLiveCodeImgUrl?true:false}" accept="image/*"
									:on-success="($event) =>{return imgOnSuccess($event, i)}"
									:before-upload="(...event) =>{return beforeUpload(event, i)}"
									:show-file-list="false">
									<el-button type="text" style="width:60px;">
										{{!v.QyWeixinGroupLiveCodeImgUrl?'上传活码':'修改活码'}}</el-button>
								</el-upload>
							</div>
						</el-form-item>
						<el-form-item label="关联群聊：" label-width="100px"
							:prop="'GroupLiveCodeDetail.'+i+'.GroupLiveCodeDetailChat'"
							:rules="rules.GroupLiveCodeDetailChat"
							:ref="'GroupLiveCodeDetail.'+i+'.GroupLiveCodeDetailChat'" style="margin-top:20px">
							<el-select v-model="v.GroupLiveCodeDetailChat" multiple filterable placeholder="请选择群聊"
								style="width:300px;">
								<el-option v-for="(v,i) in groutChatList" :key="i" :value="v.Id" :label="v.GroupName">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-form-item>
				<el-button type="primary" plain style="margin-left:50px" @click="pushCode">添加群码</el-button>
			</el-card>
			<!-- 取消保存 -->
			<el-card style="position:fixed;bottom:0px;width:100%;height:80px;text-align:center;z-index:999">
				<el-button style="width:90px;height:36px;margin-left:-100px" @click="goback">取消</el-button>
				<el-button type="primary" style="width:90px;height:36px;margin-left:40px" @click="save('ruForm')">保存
				</el-button>
			</el-card>
		</el-form>
	</div>
</template>

<script>
	import {
		qyweixinrealgrouppagelist,

	} from "@/api/TurnTomySelf"
	import {
		grouplivecodecustomerfilterBoxList,
		grouplivecodesave,
		grouplivecodeinfo,
		grouplivecodeshopfilterBoxList
	} from "@/api/sv1.0.0"
	import config from "@/config"
	export default {
		data() {
			return {
				ruForm: {
					Id: 0,
					GroupLiveCodeName: '',
					JoinGroupTip: '',
					ShopId: null,
					CustomerId: null,
					GroupLiveCodeGuid: '',
					GroupLiveCodeType: 2,
					GroupLiveCodeDetail: [{
						QyWeixinGroupLiveCodeImgUrl: '',
						GroupLiveCodeDetailChat: [],
					}]
				},
				rules: {
					GroupLiveCodeName: [{
						required: true,
						message: '请输入活码名称',
						trigger: 'blur'
					}, ],
					CustomerId: [{
						required: true,
						message: '请选择加群客服',
						trigger: 'blur'
					}, ],
					ShopId: [{
						required: true,
						message: '请选择适用门店',
						trigger: 'blur'
					}, ],
					QyWeixinGroupLiveCodeImgUrl: [{
						required: true,
						message: '请上传企微活码',
						trigger: 'blur'
					}, ],
					Group: [{
						required: true,
						message: '请选择群聊',
						trigger: 'blur'
					}, ]
				},
				JoinServiceList: [],
				storesList: [],
				groutChatList: [],

				storageList: [],

				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				showBtnDealImg: false,
				pageloading: false,
			}
		},

		created() {
			if (this.$route.query.ShopId) {
				this.ruForm.ShopId = Number(this.$route.query.ShopId)
			}
			this.getgrouplivecodeshopfilterBoxList()
			this.getqyweixinrealgrouppagelist()
			// this.getcustomerfilterBoxList()
			// if (this.$route.query.Id != 0) { //跟客户活码那里一样
			// 	this.getgrouplivecodeinfo()
			// } else {
			// 	this.getcustomerfilterBoxList()
			// }
			this.getcustomerfilterBoxList()
		},
		beforeMount() {},
		methods: {
			pushCode() {
				let data = {
					id: 0,
					QyWeixinGroupLiveCodeImgUrl: '',
					GroupLiveCodeDetailChat: []
				}
				this.ruForm.GroupLiveCodeDetail.push(data)
			},
			goback() {
				this.$router.push({
					path: '/Customer/storesLiveCode'
				})
			},
			save(formName) {
				this.$refs[formName].validate((valid, object) => {
					if (valid) {
						// alert('submit!');
						this.togrouplivecodesave()
					} else {
						this.$message.error('请完善活码配置')
						this.scrollView(object)
						return false;
					}
				});
			},
			//验证失败时滚动到第一个错误位置
			scrollView(object) {
				for (const i in object) {
					let dom = this.$refs[i]
					if (Object.prototype.toString.call(dom) !== '[object Object]') {
						dom = dom[0]
					}
					dom.$el.scrollIntoView({ // 滚动到指定节点
						// 值有start,center,end，nearest，当前显示在视图区域中间
						block: 'center',
						// 值有auto、instant,smooth，缓动动画（当前是慢速的）
						behavior: 'smooth'
					})
					break // 因为我们只需要检测一项,所以就可以跳出循环了
				}
			},
			//保存
			async togrouplivecodesave() {
				this.pageloading = true
				try {
					let data = JSON.parse(JSON.stringify(this.ruForm))
					//this.storageList是拿到时还没有转化的数据，根据群码的GroupLiveCodeDetail去查找是否是初始化的数据
					//这里需要根据GroupLiveCodeDetail去查询，如果有那么根据内部群聊数组的QyWeixinRealGroupId去分配GroupLiveCodeDetailChatId，没有则为0
					data.GroupLiveCodeDetail = data.GroupLiveCodeDetail.map((v) => {
						let msg = this.storageList.find((x) => {
							return v.GroupLiveCodeDetailId == x.GroupLiveCodeDetailId
						})
						// console.log(msg)
						if (msg) {
							v.GroupLiveCodeDetailChat = v.GroupLiveCodeDetailChat.map((m) => {
								let obj = {
									QyWeixinRealGroupId: m,
									GroupLiveCodeDetailChatId: 0,
								}
								msg.GroupLiveCodeDetailChat.map((n) => {
									if (n.QyWeixinRealGroupId == m) {
										obj.GroupLiveCodeDetailChatId = n
											.GroupLiveCodeDetailChatId
									}
								})
								return obj
							})
						} else {
							v.GroupLiveCodeDetailChat = v.GroupLiveCodeDetailChat.map((m) => {
								let obj = {
									QyWeixinRealGroupId: m,
									GroupLiveCodeDetailChatId: 0,
								}
								return obj
							})
						}
						return v
					})
					let transmission = {
						GroupLiveCode: {
							Id: data.Id,
							GroupLiveCodeName: data.GroupLiveCodeName,
							JoinGroupTip: data.JoinGroupTip,
							ShopId: data.ShopId,
							CustomerId: data.CustomerId,
							GroupLiveCodeGuid: data.GroupLiveCodeGuid,
							GroupLiveCodeType: data.GroupLiveCodeType
						},
						GroupLiveCodeDetail: data.GroupLiveCodeDetail
					}
					console.log(transmission.GroupLiveCodeDetail)
					let result = await grouplivecodesave(transmission)
					if (result.IsSuccess) {
						if (!this.$route.query.Id) {
							this.$message.success('添加成功')
						} else {
							this.$message.success('保存成功')
						}
						this.goback()
					}
				} finally {
					this.pageloading = false
				}
			},
			//删除活码
			detele(i) {
				this.ruForm.GroupLiveCodeDetail.splice(i, 1)
			},
			beforeUpload(file) {
				const size = file[0].size / 1024 / 1024 < 2;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file[0].type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('企微活码图片大小请控制在2M及以内');
					return false
				}
			},
			imgOnSuccess(file, num) {
				// console.log(file,num)
				this.ruForm.GroupLiveCodeDetail[num].QyWeixinGroupLiveCodeImgUrl = file[0]
			},
			//门店列表
			async getgrouplivecodeshopfilterBoxList() {
				let data = {
					GroupLiveCodeId: this.$route.query.Id == 'undefined' ? 0 : this.$route.query.Id
				}
				let result = await grouplivecodeshopfilterBoxList(data)
				if (result.IsSuccess) {
					// result.Result.map(item=>{
					// 	if (item.ShopId == 316){
					// 		console.log(result.Result,'门店')
					// 	}
					//   return item.ShopId == 316
					// })
					if (this.$route.query.ShopId){
						var list = result.Result.filter(item=>{
							return item.ShopId == +this.$route.query.ShopId;
						})
						if (!list.length){
							this.$message.error('门店已创建群活码或被删除，请选择其他门店');
							this.ruForm.ShopId = ''
						}
					}
					this.storesList = result.Result
				}
			},
			//获取客服列表
			async getcustomerfilterBoxList() {
				let result = await grouplivecodecustomerfilterBoxList({})
				if (result.IsSuccess) {
					// console.log(result.Result,'客服')
					this.JoinServiceList = result.Result
				}
				
				if (this.$route.query.Id != 0) { //跟客户活码那里一样
					this.getgrouplivecodeinfo()
				}
			},
			//获取群聊列表
			async getqyweixinrealgrouppagelist() {
				let data = {
					IsDissolution: false,
					IsQyWeixinRealGroupSelect: true,
					Skip: 0,
					Take: 99999,
				}
				let result = await qyweixinrealgrouppagelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result.Results,'群聊')
					this.groutChatList = result.Result.Results
				}
			},
			//初始化
			async getgrouplivecodeinfo() {
				this.pageloading = true

				let data = {
					Id: this.$route.query.Id != 'undefined' ? this.$route.query.Id : 0
				}
				let result = await grouplivecodeinfo(data)
				if (result.IsSuccess) {
					let ruform = {}
					this.storageList = JSON.parse(JSON.stringify(result.Result.GroupLiveCodeDetail))
					ruform = result.Result.GroupLiveCode
					ruform.GroupLiveCodeDetail = []
					ruform.GroupLiveCodeDetail = result.Result.GroupLiveCodeDetail.map((v) => {
						v.GroupLiveCodeDetailChat = v.GroupLiveCodeDetailChat.map((x) => {
							return x.QyWeixinRealGroupId
						})
						return v
					})

					this.ruForm = ruform;

					let BoxList = await grouplivecodecustomerfilterBoxList({})
					if (BoxList.IsSuccess) {
						// console.log(result.Result,'客服')
						this.JoinServiceList = BoxList.Result
						let a = this.JoinServiceList.find((v) => {
							return v.Id == this.ruForm.CustomerId
						})
						// console.log(a)
						if (!a) {
							this.ruForm.CustomerId = ''
						}
					}
				}
				this.pageloading = false
			},
		},
	}
</script>

<style lang="less" scoped>
	.titleFont {
		font-size: 16px;
	}

	.JoinService-Class ::v-deep .el-form-item__error {
		top: 65px
	}

	.blueBox {
		width: 1000px;
		height: 45px;
		border: 1px solid #409eff;
		background: rgba(64, 158, 255, 0.07);
	}

	.elUploadClass {
		margin-top: 115px;
		margin-left: 20px;
		/* border:1px solid black; */
	}
</style>
<style>
	.uoloadSty .el-upload--picture-card {
		display: none
	}
</style>
